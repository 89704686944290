<template>
    <div v-if="dataLoaded">
        <div id="preload" style="display: none;">
            <img src="~@/assets/vector_dark.svg" rel="preload" />
            <img src="~@/assets/vector_white.svg" rel="preload" />
        </div>
        <PageBuilder :cmsBlocks="home.attributes.cmsBlocks"/>
    </div>
</template>

<script>
    import { cmsPageQuery } from '@/queries'
    import { config } from '@/config'

    import PageBuilder from '@/components/PageBuilder'

    export default {
        name: 'Home',

        components: {
            PageBuilder
        },

        data() {
            return {
                home: null
            }
        },

        apollo: {
            home: {
                query: cmsPageQuery,
                variables() {
                    return {
                        title: 'home'
                    }
                },
                fetchPolicy: 'network-only',
                notifyOnNetworkStatusChange: true,
                update: data => data.cmsPages.data[0]
            }
        },

        metaInfo() {
            var meta = []
            var style = []

            if(this.metaTitle){
                meta.push({
                    vmid: 'og:title',
                    name: 'og:title',
                    content: this.metaTitle
                })
            }
            if(this.metaDescription){
                meta.push({
                    vmid: 'description',
                    name: 'description',
                    content: this.metaDescription
                })
                meta.push({
                    vmid: 'og:description',
                    name: 'og:description',
                    content: this.metaDescription
                })
            }
            if(this.metaImage){
                meta.push({
                    vmid: 'og:image',
                    name: 'og:image',
                    content: this.metaImage
                })
            }
            if(this.css){
                style.push({
                    vmid: 'cms-page-style',
                    type: 'text/css',
                    innerHTML: this.css
                })
            }

            return {
                title: this.metaTitle,
                meta: meta,
                style: style
            }
        },

        computed: {
            dataLoaded() {
                return this.home != null
            },

            metaTitle() {
                if(this.home){
                    var title = this.home.attributes.metaTitle ? this.localize(this.home.attributes.metaTitle) : this.localize(this.home.attributes.title)
                    return config.globalPageTitle + ' - ' + title
                } else {
                    return null
                }
            },

            metaDescription() {
                if(this.home){
                    return this.home.attributes.metaDescription ? this.localize(this.home.attributes.metaDescription) : this.localize(this.home.attributes.subtitle)
                } else {
                    return null
                }
            },

            metaImage() {
                if(this.home && this.home.attributes.metaImage && this.localize(this.localize(this.home.attributes.metaImage).data !== null)) {
                    return this.getImageUrl(this.localize(this.home.attributes.metaImage).data.attributes.url)
                } else {
                    return null
                }
            },

            css() {
                if(this.home){
                    return this.home.attributes.css ? this.home.attributes.css : null
                }
            }
        }
    }
</script>
